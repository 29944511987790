import React from 'react';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import Image from '../components/atoms/Image';
import PageHero from '../components/organisms/PageHero';
import TwoColumn from '../components/organisms/TwoColumn';
import template from '../../static/images/games/jungle-game-template.jpg';
import Border from '../assets/uf_border-2.inline.svg';

const Holder = styled.div`
  p { ${props => props.theme.fluidType( 1 )}; }
  .button {
    margin-top: 2rem;
  }
`;

const EntriesHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: repeat(2, 1fr);
  }
`;

const EndEntry = styled.div`
  border: 1px dashed;
  padding: 4rem 2rem;
  text-align: center;
  height: calc(100% - 4rem);
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  display: flex;
  align-items: center;
  justify-content: center;
`;

function GamesPage() {

  return (
    <>
      <Seo title="Games & Competitions" description="What is lurking in the jungle? Our first game can be played here."/>
      <Holder>
        <h1>Games & Competitions</h1>
        <TwoColumn>
          <div>
            <h2>What is lurking in the jungle?</h2>
            <p>Our very first game is a drawing game. Can you show us what is lurking in the
              jungle?! Is it a monkey with glasses? Is it a gnome with a backwards hat? Is it a
              funnel web spider doing the washing up? You tell us!</p>
            <p>If you already own our magic colouring book then you have the template on the back
              page. If not you can download it here. We love getting drawings so please send them
              in. Everyone is a winner.</p>
            <p><a className="button" href={template} target="_blank" rel="noopener noreferrer">Download
              template</a></p>
          </div>
          <Image imgName="jungle-game-template.jpg"/>
        </TwoColumn>
        <h2>Your entries!</h2>
        <EntriesHolder>
          <div>
            <Image imgName="isla-m-5.jpg"/>
            <p>Isla M - 5yrs</p>
          </div>
          <EndEntry>
            <div>
              <p className="h2">Is your entry here? We could do with some more.</p>
              <p><a href={template} target="_blank" rel="noopener noreferrer">Download
                template to enter</a></p>
            </div>
          </EndEntry>
        </EntriesHolder>
      </Holder>
    </>
  )
}

export default GamesPage;

