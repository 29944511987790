import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: 1fr 1fr;
    align-items: start;
    > :nth-child(odd) {
      grid-column: ${props => props.switchDesktop ? '2/3' : '1/2'};
    }
    > :nth-child(even) {
      grid-column: ${props => props.switchDesktop ? '1/2' : '2/3'};
    }
  }
  > div {
    margin: 0;
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

function TwoColumn({switchDesktop, children}) {
    return (
      <Holder switchDesktop={switchDesktop}>
        {children}
      </Holder>
    )
}

TwoColumn.propTypes = {
  switchDesktop: PropTypes.bool
};

TwoColumn.defaultProps = {
  switchDesktop: false
};

export default TwoColumn;
